import { API_URL } from "../constants";

const addPromotion = async (token, data) => {
    return await fetch(`${API_URL}/promotions/${data.businessId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(data)
    })
    .then(response =>{
      if(!response.ok){
        return null;
      }
      return response.json();
    })
    .then (data =>{
      return data;
    })
    .catch(error =>{
      console.error('Error al guardar datos: ' + error);
      return null;
    })
  }
  
  const uploadPromotionAssets = async(token, assetData) => {
    return await fetch(API_URL + '/promotion/upload',{
      method:'POST',
      headers: {
        'Authorization': 'Bearer ' + token
      },
      body: assetData
    })
    .then(response =>{
      if(!response.ok) return false;
  
      return response.json();
    })
    .catch(error => {
      console.error(error);
      return false;
    })
  }
  
  const updatePromotionData = async(token, data, promoId) => {
    return await fetch(API_URL + '/promotions/' + promoId,{
      method:'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ [data.field]:data.data })
    })
    .then(response =>{
      if(!response.ok){
        return null;
      }
      return true;
    })
    .catch(error => {
      console.error('Error al actualizar: ', error);
      return null;
    });
  }
  
  const updatePromotionType = async(token, data, promoId) => {
    return await fetch(API_URL + '/promotions/' + promoId + '/type',{
      method:'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(data)
    })
    .then(response =>{
      if(!response.ok){
        return null;
      }
      return true;
    })
    .catch(error => {
      console.error('Error al actualizar: ', error);
      return null;
    });
  }
  
  const enablePromotion = async(token, id) => {
    return await fetch(API_URL + '/promotions/' + id, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(response =>{
      if(!response.ok){
        return null;
      }
      return true;
    })
    .catch(error => {
      console.error('Error al actualizar: ', error);
      return null;
    });
  }
  
  const disablePromotion = async(token, id) => {
    return await fetch(API_URL + '/promotions/' + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(response =>{
      if(!response.ok){
        return null;
      }
      return true;
    })
    .catch(error => {
      console.error('Error al actualizar: ', error);
      return null;
    });
  }

  const setMainPromotion = async(token, businessUser, promoId, data) => {
    return await fetch(API_URL + '/promotion/' + businessUser + '/' + promoId, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
      })
      .then(response =>{
        if(!response.ok){
          return null;
        }
        return true;
      })
      .catch(error => {
        console.error('Error al actualizar: ', error);
        return null;
      });
  }

  export {
    addPromotion, uploadPromotionAssets, updatePromotionData, enablePromotion, disablePromotion, updatePromotionType, setMainPromotion
  }