import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

const promoTypes =['','Descuento', 'Promoción', 'Producto'];

const CustomAmountSelect = ({MaxOptions, PlusOptions, SelectedValue, HandleSelectChange, ExtraStyle}) => {

    const renderDiscountOptions = (max, plus) =>{
        const options = [];
        
        for (let i = plus; i <= max; i += plus) {
            options.push(<option key={i} value={i}>{i}</option>);
        }

        return options;
    }
    
    const handleChange = (event) => {
        HandleSelectChange(parseInt(event.target.value, 10))
    }

    return(
        <select name='amount1' className={"p-2 border rounded-md " + ExtraStyle}  value={SelectedValue}
            disabled={false} onChange={handleChange}>
            { renderDiscountOptions(MaxOptions, PlusOptions) }
        </select>
    )
}



CustomAmountSelect.propTypes = {
    MaxOptions: PropTypes.number.isRequired,
    PlusOptions: PropTypes.number.isRequired,
    SelectedValue: PropTypes.number.isRequired,
    HandleSelectChange: PropTypes.func,
    ExtraStyle: PropTypes.string,
}

export default CustomAmountSelect;