import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
// 
const CheckBox = ({Label, Selected, SelectCallback, ToolTip}) => {
    return(
        <div class="inline-flex items-center group">
            <label class="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor="ripple-on"
                data-ripple-dark="true">
                <input id="ripple-on" type="checkbox"
                    class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10" 
                    checked={Selected} onChange={(event) => SelectCallback(!Selected)}/>
                <span
                    class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                    stroke="currentColor" stroke-width="1">
                    <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"></path>
                    </svg>
                </span>
            </label>
            <div>
            <label class="mt-px font-light text-gray-700">
                {Label}
            </label>
            { ToolTip && 
            <div class="group-hover:opacity-100 absolute z-10 right-0 px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 rounded-lg shadow-sm opacity-0 dark:bg-yellow-600">
                {ToolTip}
            </div>
        }
        </div>
        </div>
    )
}



CheckBox.propTypes = {
    Label: PropTypes.string,
    Selected: PropTypes.bool.isRequired,
    SelectCallback: PropTypes.func.isRequired,
    ToolTip: PropTypes.string,
}

export default CheckBox;