import React, { useState } from 'react';

import RouterApp from './Router';

import { useUser } from './context/UserContext';

import Login from './pages/Login';

function App() {
  const { user } = useUser();

  if(!user) return <Login />

  return (
    <div class="flex h-screen font-mono">
       <RouterApp />
    </div>
  );
}

export default App;
