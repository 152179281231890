import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import { MdModeEdit, MdDone, MdClear } from "react-icons/md";

const EditableLabel = ({labelText, labelClickCallback, updateLabelCallback, labelCustomStyle, maxLength}) => {
    const [text, setText] = useState(labelText);
    const [newText, setNewText] = useState(labelText);
    const [isEditing, setIsEditing] = useState(false);
    
    const enableEditing = () => {
        setNewText(labelText);
        setIsEditing(true);
    }

    const cancelEditing = () => {
        setNewText(text);
        setIsEditing(false);        
    }

    const saveText = () => {
        if(newText === '') return;

        updateLabelCallback(newText);
        setIsEditing(false);
    }

    const setStyle = () => {
        var extraStyle = text !== ''? "": " ";
        var style;

        if(labelCustomStyle && labelCustomStyle.length >0 ){
            style = labelCustomStyle;
        }else{
            style = "cursor-pointer " + extraStyle;
        }
        
        if(labelText && labelText.length > 0){
            style += " h-full w-fit";
        }
        else{
            style += " w-full h-10 border-2 rounded-xl ";
        }

        return style;
    }

    const labelClick = () => {
        if(labelClickCallback){
            labelClickCallback();
        }
    }

    return(
        <div>
            {
                isEditing ? (
                    <div  class="flex items-center w-full">
                        <input id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[80%] p-2.5 ml-auto mr-auto" maxLength={maxLength?maxLength:30}
                            value={newText} onChange={ e => setNewText(e.target.value) }></input>
                        <MdDone class="ml-4 cursor-pointer bg-green-400 w-8 h-8 rounded p-1" onClick={() => saveText()} />
                        <MdClear class="ml-4 cursor-pointer bg-red-500 w-8 h-8 rounded p-1" onClick={() => cancelEditing()} />
                    </div>
                ) :
                (
                    <div  class="flex items-center relative">
                        <div data-modal-target="staticModal" data-modal-toggle="staticModal" type="button" 
                            class={setStyle()}
                            onClick={() => labelClick()}>{labelText}</div>
                        <MdModeEdit class="hidden group-hover:block absolute right-[-10px] cursor-pointer hover:scale-150" onClick={() => enableEditing()} />
                    </div>
                )
            }
            
        </div>
    )
}

EditableLabel.propTypes = {
    labelText: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    labelCustomStyle: PropTypes.string,
    labelClickCallback: PropTypes.func,
    updateLabelCallback: PropTypes.func
}

export default EditableLabel;