import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { MdModeEdit, MdDone, MdClear } from "react-icons/md";

import CustomAmountSelect from './CustomAmountSelect.js';

const promoTypes =['','Descuento', 'Promoción', 'Producto'];

const PromotionType = ({type, amount, id, updateCallBack}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [typeV, setType] = useState(type);
    const [newAmount, setNewAmout] = useState(amount);

    const togleEdit = (edit) => {
        setType(type);
        setNewAmout(amount);
        setIsEditing(edit);
    }

    const convertToPromotion = () => {
        var tmpAmount;
        try{
            tmpAmount = newAmount.split("x").map(Number);
        }
        catch(err){
            tmpAmount = [1,1];
        }

        return tmpAmount;
    }

    const setNewPromotion = (value, po) => {
        var tmpAmount = convertToPromotion();

        tmpAmount[po] = value;

        setNewAmout(tmpAmount[0] + "x" + tmpAmount[1]);
    }

    const renderOptions = () => {
        switch(typeV){
            case 1:
                return(
                    <div className="mt-2 flex flex-row items-center">
                        <CustomAmountSelect ExtraStyle={"ml-10"} MaxOptions={100} PlusOptions={5} SelectedValue={newAmount} 
                            HandleSelectChange={(data) => setNewAmout(data)}/>
                        <p className="text-3xl ml-2">%</p>
                    </div>
                )
            case 2:
                var promo = convertToPromotion();

                return(
                    <div className="mt-2 flex flex-row">
                        <CustomAmountSelect ExtraStyle={"ml-10"} MaxOptions={10} PlusOptions={1} SelectedValue={promo[0]} 
                            HandleSelectChange={(data) => setNewPromotion(data, 0)}/>
                        <p className="ml-2 text-2xl font-bold">x</p>
                        <CustomAmountSelect ExtraStyle="ml-2" MaxOptions={10} PlusOptions={1} SelectedValue={promo[1]} 
                            HandleSelectChange={(data) => setNewPromotion(data, 1)}/>
                    </div>
                )
            case 3:
                return(<></>);
        }
    }

    const savePromoChanges = () => {
        const data = {
            type: typeV,
            discountAmount: newAmount
        }

        updateCallBack(data);
        setIsEditing(false);
    }

    const getPromoText = () => {
        switch(typeV){
            case 1:
                return amount + '%';
            case 2:
                return amount;
            case 3:
                return ''
        }
    }

    return(
        <div class="inline-flex items-center w-full">
            { isEditing? 
                <div className="w-full">
                    <div className="flex flex-col w-full">
                        <div className="flex flex-row items-center">
                            <p className="text-lg mr-2">Tipo: </p>
                            <select name="type" className="p-2 border rounded-md" value={typeV} onChange={(event)=> setType(parseInt(event.target.value, 10))}>
                                <option value={1}>Descuento</option>
                                <option value={2}>Promocion</option>
                                <option value={3}>Producto</option>
                            </select>
                            
                        </div>
                        { renderOptions() }
                        <div className="w-full flex flex-row mt-5">
                            <button className="bg-green-500 rounded h-10 ml-auto mr-auto w-[40%] flex flex-row items-center justify-center"
                                onClick={() => savePromoChanges()}><MdDone className="h-full w-full"/></button>
                            <button className="bg-red-500 rounded h-10 ml-auto mr-auto w-[40%] flex flex-row items-center justify-center"
                                onClick={() => togleEdit(false)}><MdClear className="h-full w-full"/></button>
                        </div>
                    </div>
                </div> :
                <div className="flex flex-row items-center group">
                    <p className="text-lg">Tipo: {promoTypes[typeV]} </p> 
                    <p className="text-lg ml-2">  { getPromoText() }</p>
                    <MdModeEdit class="hidden group-hover:block ml-4 cursor-pointer hover:scale-150" onClick={() => togleEdit(true)} />
                </div>
    }
        </div>
    )
}



PromotionType.propTypes = {
    type: PropTypes.number,
    amount: PropTypes.string,
    updateCallBack: PropTypes.func.isRequired
}

export default PromotionType;