import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie-player';

import loading from '../assets/animations/loading_circle.json';

const ActionButton = ({Label, ClickCallback, Loading}) => {
    const renderLabel = () => {
        if(Loading){
            return <Lottie className='w-full h-full' animationData={loading} play loop speed={1.5}/>
        }
        else{
            return Label;
        }
    }

    return(
        <button class="mt-12 bg-yellow-500 w-3/4 h-12 text-white rounded text-2xl font-semibold shadow-xl" 
            type="button" onClick={() => ClickCallback()} disabled={Loading}>
                { renderLabel() }
        </button>
    )
}

ActionButton.propTypes ={
    Label: PropTypes.string.isRequired,
    ClickCallback: PropTypes.func.isRequired,
    Loading: PropTypes.bool
}

export default ActionButton;