import React, { useEffect, useState } from "react";
import { useQuery } from '@tanstack/react-query';
import { MdModeEdit, MdDone } from "react-icons/md";

import { useCategories } from "../context/CategoriesContext.js";
import { useUser } from '../context/UserContext.js';

import { enableCategory, disableCategory, updateCategoryData, fetchCategories } from "../services/ziti.js";

import AddCategory from "./AddCategory.js";
import CategoryDetails from './CategoryDetails.js';
import EditableLabel from '../components/EditableLabel.js';

const Categories = () => {
    // const { data, isLoading, error } = useQuery('categories', fetchData);

    const [isOpen, setIsOpen] = useState(false);
    const [modal, setModal] = useState("");
    const [selectedCategory, setSelectedCategory] = useState({});

    const { user } = useUser();
    // // const query = useQuery(['categories', user], () => fetchCategories(user.token));
    // const query = useQuery({
    //     queryKey: ['categories', user.token],
    //     queryFn: () => fetchCategories(user.token),
    //   });
    // console.log(query);

    const { categories, isLoadingCategories, setCategoriesData } = useCategories();
    
    const togleModal = (modal, data) => {
        setSelectedCategory(data);
        setIsOpen(!isOpen);
        setModal(modal);
      };

    const ModalComponent = {
        Details: <CategoryDetails CloseModal={togleModal} Data={selectedCategory} />,
        New: <AddCategory CloseModal={togleModal}/>
    }[modal];

    useEffect(() => {
        if(!isLoadingCategories && !categories){
            console.log('load categories');
        }
        else{
            
        }
    });

    const togleCategory = (category) => {
        const { _id } = category;
        var res = false;

        if(category.enabled){
            res = disableCategory(user.token, _id);
        }
        else{
            res = enableCategory(user.token, _id);
        }
        

        if(res){
            const nCats = categories.map((category => {
                if(category._id === _id){
                    return { ...category, enabled: !category.enabled };
                }
                return category;
            }));
            setCategoriesData(nCats);
        }
    }

    const updateCategoryName = (text, id) => {
        const data = {
            'id': id,
            'data':text,
            'field':'name'
        }

        const res = updateCategoryData(user.token, data);

        if(res){
            const nCats = categories.map((category => {
                if(category._id === id){
                    return { ...category, name: text };
                }
                return category;
            }));
    
            setCategoriesData(nCats);
        }        
    }

    return (
        <div class="w-full">
                <div class="flex-row h-10 text-start lg:text-center mt-1  w-full">
                    <p class=" mr-10 font-semibold text-4xl">Categorias</p>                    
                </div>
                <div class="mt-2 lg:mt-20 p-5 flex justify-center items-center">
                    <div class="lg:w-3/4 inner-div w-full lg:min-w-[50%]">
                        <div class="h-10 flex-row">
                            <button
                                type="button"
                                class=" float-right mr-[10%] inline-block rounded bg-yellow-600 px-6 pb-2 pt-2.5 text-s font-medium 
                                leading-normal text-white shadow-md transition duration-150 ease-in-out 
                                hover:bg-primary-600 hover:shadow-xl focus:bg-primary-600 focus:shadow-xl focus:outline-none 
                                focus:ring-0 active:bg-primary-700 active:shadow-xl dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] 
                                dark:hover:shadow-xl dark:focus:shadow-xl dark:active:shadow-xl"
                                data-te-toggle="modal"
                                data-te-target="#staticBackdrop"
                                data-te-ripple-init
                                data-te-ripple-color="light"
                                onClick={() => togleModal('New')}>
                                Nueva Categoria
                            </button>
                        </div>
                        <table class="min-w-50 text-left text-sm font-light w-full">
                            <thead class="border-b font-medium dark:border-neutral-500 w-1/12">
                                <tr>
                                    <th scope="col" class="px-6 py-4 text-xl w-1">Estado</th>
                                    <th scope="col" class="px-6 py-4 text-xl">Nombre</th>
                                </tr>
                            </thead>
                            <tbody>
                                { categories && categories.map((category) => {
                                    return(
                                        <tr class="border-b dark:border-neutral-500 hover:bg-yellow-100 group" key={category._id}>
                                            <td class="whitespace-nowrap px-6 py-4 font-medium">
                                                <label class="relative inline-flex items-center cursor-pointer">
                                                    <input type="checkbox" value="testing" class="sr-only peer" checked={category.enabled} onChange={() => togleCategory(category)}/>
                                                    <div class="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                </label>
                                            </td>
                                            <td class="whitespace-nowrap px-6 py-4 text-lg">
                                                <EditableLabel labelText={category.name} labelClickCallback={() => togleModal('Details', category)} updateLabelCallback={(text) => updateCategoryName(text, category._id)} />
                                            </td>
                                            <td></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
            </div>

            { isOpen && (
                <>{ModalComponent}</>
            )}
        </div>
    );
};

export default Categories;