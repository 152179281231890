import React, { useState } from "react";

import { useUser } from "../context/UserContext";

const Home = () => {
    const { user } = useUser();

    return (
        <div>
            <h1 class="text-black text-8xl">Home</h1>
            <div>
                <p class='text-lg'>Bienvenido {user.name} {user.lastName}!</p>
            </div>
        </div>
    );
};

export default Home;