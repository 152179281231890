import React from "react";
import PropTypes from 'prop-types';
import { BiErrorCircle } from 'react-icons/bi';

const UploadAssetModalResult = ({callBack}) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>

            <div className="modal-container bg-white 2xl:w-[30%] mx-auto rounded shadow-lg z-50 overflow-y-auto">
                <div className="modal-content py-4 text-left px-6">
                    <div className="flex justify-between items-center">
                    </div>
                </div>
                <div class="w-fullp-2">
                    <div class="flex flex-col items-center p-4">
                        <div class="flex flex-row items-center">
                            <BiErrorCircle class="w-[30%] h-[30%]"/>
                            <p class="text-xl ml-4">Hubo un error al subir los archivos, puedes subirlos mas tarde desde los detalles del negocio.</p>
                        </div>
                        <div class="w-full, h-10 flex flex-row mt-6 mb-2 space-x-10">
                            <button class="h-10 w-32 bg-yellow-600 p rounded hover:shadow-xl" onClick={() => callBack()}>Continuar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

UploadAssetModalResult.propTypes = {
    callBack: PropTypes.func.isRequired,
}

export default UploadAssetModalResult;