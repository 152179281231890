import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { GoPlusCircle, GoListUnordered } from 'react-icons/go';

import { useCategories } from '../context/CategoriesContext';
import { useUser } from '../context/UserContext';

import { addSubCategory, enableSubCategory, disableSubCategory, updateSubCategoryData } from '../services/ziti';
import EditableLabel from '../components/EditableLabel';

const CategoryDetails = ({CloseModal, Data}) => {
    const [categoryData, setCategoryData] = useState(Data);
    const [subCategoryName, setSubCategoryName] = useState('');
    const [showNewSubCat, setShowNewSubCat] = useState(false);
    const [isSavingData, setIsSavingData] = useState(false);

    const { categories, setCategoriesData } = useCategories();
    const { user } = useUser();

    const togleNewSubCat = () => setShowNewSubCat(!showNewSubCat);

    const addNewSubCat = async () => {
        if(subCategoryName === '' ) return;
        if(isSavingData) return;
        
        const newCategory = {
            'SubCategoryName':subCategoryName,
            'categoryId': Data._id
        }

        setIsSavingData(true);
        const addRes = await addSubCategory(user.token, newCategory);

        if(addRes.data){
            const newSubcategory = addRes.data;
            const nCats = categories.map((category => {
                if(category._id === Data._id){
                    return { ...category, subcategoriesId:[...category.subcategoriesId, newSubcategory] };
                }
                return category;
            }));
            setCategoriesData(nCats);
    
            const newCategoryData = {
                ...categoryData,
                subcategoriesId:[ ...categoryData.subcategoriesId, newSubcategory]
            }
            setCategoryData(newCategoryData);
        }

        setSubCategoryName('');
        setShowNewSubCat(false);
        setIsSavingData(false);
    }

    const togleSubCategory = (subCat) => {
        if(isSavingData) return;

        var res = null;

        const id = subCat._id;

        setIsSavingData(true);

        if(subCat.enabled){
                res = disableSubCategory(user.token, id);
        }
        else{
                res = enableSubCategory(user.token, id);
        }

        if(res){
                const updatedSubCategories = categoryData.subcategoriesId.map((subCategory) => {
                    if(subCategory._id === id){
                        return { ...subCategory, enabled: !subCategory.enabled };
                    }
                    return subCategory;
                });

                const nCats = categories.map((category => {
                    if(category._id === Data._id){
                        return { ...category, subcategoriesId:updatedSubCategories };
                    }
                    return category;
                }));
                setCategoriesData(nCats);

                const newCategoryData = {...categoryData, subcategoriesId: updatedSubCategories};
                setCategoryData(newCategoryData);
        }

        setIsSavingData(false);

    }

    const updateSubCategoryName = (data, id) => {
        const subCategoryData = {
            'id': id,
            'data':data,
            'field':'name'
        }

        const res = updateSubCategoryData(user.token, subCategoryData);
        
        if(res){
            const updatedSubCategories = categoryData.subcategoriesId.map((subCategory) => {
                if(subCategory._id === id){
                    return { ...subCategory, name: subCategoryData.data };
                }
                return subCategory;
            });

            const nCats = categories.map((category => {
                if(category._id === Data._id){
                    return { ...category, subcategoriesId:updatedSubCategories };
                }
                return category;
            }));
            setCategoriesData(nCats);

            const newCategoryData = {...categoryData, subcategoriesId: updatedSubCategories};
            setCategoryData(newCategoryData);
        }   
    }

    return(
        <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>

                    <div className="modal-container bg-white w-5/12 min-w-[70%]  mx-auto rounded shadow-lg z-50 overflow-y-auto">
                        <div className="modal-content py-4 text-left px-6">
                            <div className="flex justify-between items-center">
                                <h2 className="text-2xl font-semibold">Categoria: {categoryData.name}</h2>
                                <button
                                    onClick={() => CloseModal('Details')}
                                    className="text-gray-600 hover:text-gray-800 focus:outline-none"
                                    >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon icon-tabler icon-tabler-x"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="#000000"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <line x1="18" y1="6" x2="6" y2="18" />
                                        <line x1="6" y1="6" x2="18" y2="18" />
                                    </svg>
                                </button>
                            </div>                    
                            <div class="mt-5">
                                <div class="w-11/12 ml-auto mr-auto">
                                    <div class="border-b mb-2">
                                        <div class="h-10 flex items-center">
                                            <p class="text-lg font-semibold">Subcategorias</p>
                                            <button class="h-8 ml-5" onClick={() => togleNewSubCat() }><GoPlusCircle class="float-left h-6 " size="2em"/></button>
                                        </div>
                                        { showNewSubCat && (
                                            <div class="mt-2 mb-2 flex-row h-24 text-justify">
                                                <div class="flex flex-col items-center justify-center">
                                                    <div class="w-full">
                                                        <label class="block text-gray-500 font-bold text-lg mb-1 md:mb-0 pr-4" for="subcat">
                                                            Nombre
                                                        </label>
                                                    </div>
                                                    <div class="w-full lg:w-[80%] flex flex-row">
                                                        <input id="subcat" class="bg-gray-50 appearance-none border border-gray-300 rounded w-9/12 py-2 px-4 text-gray-900 leading-tight focus:outline-none focus:bg-white" maxLength={35} 
                                                        type='text' value={subCategoryName} onChange={e => setSubCategoryName(e.target.value)}/>
                                                        <button class="bg-green-500 p-2 rounded hover:shadow-lx text-white font-bold py-2 px-4 ml-2"
                                                            onClick={() => addNewSubCat()}>
                                                                Agregar
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div class="w-full h-full mt-1 p-2">
                                    { categoryData.subcategoriesId && categoryData.subcategoriesId.map((subcategory) => {
                                        return(
                                            <div class="mb-2 hover:bg-yellow-200 hover:shadow-lg flex-row md:items-center h-10 group"  key={subcategory._id}>
                                                <label class="relative inline-flex items-center cursor-pointer float-left h-full ml-10">
                                                    <input type="checkbox" value="testing" class="sr-only peer" checked={subcategory.enabled}  onChange={() => togleSubCategory(subcategory)}/>
                                                    <div class="w-10 h-5 bg-gray-200 peer-focus:outline-none
                                                     rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] 
                                                     after:absolute after:top-[10px] after:left-[0px] after:bg-white after:border-gray-300 after:border 
                                                     after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                                                </label>
                                                {/* <div class="h-full ml-24">
                                                    <p class="align-middle inline-block text-xl">{subcategory.name}</p>
                                                </div> */}
                                                <div class="h-full ml-24 flex items-center">
                                                    <EditableLabel labelText={subcategory.name} labelClickCallback={() => null} updateLabelCallback={(text) => updateSubCategoryName(text, subcategory._id)}/>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
    );
};

CategoryDetails.propTypes = {
    CloseModal: PropTypes.func.isRequired,
    Data: PropTypes.object
}

export default CategoryDetails;
