import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import { IoIosArrowBack } from "react-icons/io";
import { TbWorldWww, TbBrandInstagram } from 'react-icons/tb';
import { BsFacebook, BsTrashFill, BsPlusCircle, BsCloudUpload } from 'react-icons/bs';
import { GrUpdate } from 'react-icons/gr';

import { useUser } from '../context/UserContext.js';
import { useCategories } from "../context/CategoriesContext.js";
import { useBusiness } from "../context/BusinessContext.js";

import EditableLabel from "../components/EditableLabel.js";
import ComboBox from '../components/ComboBox.js';
import Switch from "../components/Switch.js";
import ImageUploadPreview from '../components/ImageUploaderPreview.js';
import SelectScheduleModal from '../components/SelectScheduleModal.js';
import Loading from '../components/Loading.js';
import PromoModal from "../components/PromoModal.js";

import { weekday, compareDay, GetBusinessAssetURL, GetArrayPositionFromString } from "../utils.js";

import { disable, fecthBusinessDetail, updateBusinessData, uploadBusinessAssets } from "../services/ziti.business.js";
import { updatePromotionData, enablePromotion, disablePromotion, uploadPromotionAssets, updatePromotionType, setMainPromotion } from "../services/ziti.promotions.js";

import { FormatDate } from '../utils.js';
import { ASSETS_URL } from "../constants.js";

import logo from '../assets/ziti_icon.png'
import CheckBox from "../components/CheckBox.js";
import PromotionType from "../components/PromotionType.js";

const BusinesDetails = () => { 
    const [businessId, setBusinessId] = useState('');
    const [businessInfo, setBusinessInfo] =useState({});
    const [businessPromos, setBusinesPromos] =useState([]);
    const [businessLoaded, setBusinessLoaded] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);
    const [categorySelected, setCategorySelected] = useState({});
    const [subCategorySelected, setSubCategorySelected] = useState({});
    const [subCategories, setSubCategoriesData] = useState({});
    const [bSchedule, setSchedule] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [bAssets, setbAssets] = useState({});
    const [isUploadingAssets, setIsUploadingAssets] = useState(false);
    const [updateSubCat, setUpdateSubCat] = useState(0);
    const [showPromoModal, setShowPromoModal] = useState(false);
    const [promoAction, setPromoAction] = useState(0);
    const [promoAssetChanges, setPromoAssetChanges] = useState([]);
    const [isLoadingDetails, setIsLoadingDetails] = useState(false);

    const { categories, isLoadingCategories, setCategoriesData } = useCategories();
    const { user } = useUser();
    const { business, setBusinessData } = useBusiness();

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() =>{
        getBusinessDetails();
        
    });

    const getBusinessDetails = async() =>{
        if(businessLoaded) return;
        setBusinessId(id);
        setIsLoadingDetails(true);

        const response = await fecthBusinessDetail(user.token, id, null);
        
        const bInfo = response.business[0];
        setBusinessInfo(bInfo);
        setBusinesPromos(response.promos);

        if(bInfo.subcategoriesId){
            let categoryObject = null;
            let subcategoryObject = null;
            categories.map(category => {
                const subcategory = category.subcategoriesId.find(sub => sub._id === bInfo.subcategoriesId);
                if (subcategory) {
                    categoryObject = category;
                    subcategoryObject = subcategory;
                }
            });
            if(updateSubCat === 0){
                setSubCategoriesData(categoryObject.subcategoriesId);

                setCategorySelected({id: categoryObject._id, name: categoryObject.name});
                setSubCategorySelected({id: subcategoryObject._id, name: subcategoryObject.name});
            }
        }
        setSchedule(bInfo.schedule);
        setIsLoadingDetails(false);
        setBusinessLoaded(true);
    }

    const renderLogoImage = (url, altText, user) => {
        if(url){
            const imgUrl = ASSETS_URL + user + '/' + url;
            
            return <img src={imgUrl} class="h-32 bg-yellow-100 aspect-square border rounded-lg" alt={altText}/>
        }
        else{
            return <img src={logo} class="p-1 opacity-50 bg-yellow-100 border rounded-lg" alt={altText}/>
        }
    }

    const togleNewSchedule = () => {
        setIsOpen(!isOpen);
    }

    const selectCategory = (item) => {
        // setsubCatArray(null);
        if(item){
            setCategorySelected({id:item._id, name: item.name});
            setSubCategoriesData(item.subcategoriesId);
            setSubCategorySelected(null);
            setUpdateSubCat(1);
        }
        else{
            setSubCategoriesData(null);
            setCategorySelected(null);
        }
        
        setSubCategorySelected(null);
        // var subCats = categories.find((category) => category._id === item._id)?.subcategoriesId || [];

        // setsubCatArray(subCats);
    }

    const selectSubCategory = (subcat) => {
        if (subcat){
            setSubCategorySelected({id:subcat._id, name:subcat.name});
            setUpdateSubCat(2);
        }
    }

    const showPromotionModal = (show, action, promo) => {
        setPromoAction(action);
        setShowPromoModal(show);
    }

    const promoActionHandler = (promo) =>{
        if(promoAction === 1){
            addPromo(promo);
        }
        else if(promoAction === 2){
            updatePromo(promo);
        }
    }

    const addPromo = (promo) => {
        var promos = [...businessPromos, promo];
        setBusinesPromos(promos);
    }

    const updatePromo = (id, field, data) => {
        const updatedPromos = businessPromos.map((promo) =>{
            if(promo._id === id){
                if(field==='promotion'){
                    return { ...promo, ...data };
                }
                else{
                    return { ...promo, [field]:data };
                }
            }
            else if(field === 'mainPromo'){
                return { ...promo, mainPromo: false };
            }

            return promo;
        });

        setBusinesPromos(updatedPromos);
    }

    const updatePromoAsset = async(saveAsset) => {
        const id = saveAsset.id;
        const asset = saveAsset.asset;

        const formData = new FormData();
        formData.append('businessUser', businessInfo.userName);
        formData.append('id', id);
        formData.append('ref', 'image');
        formData.append('image', asset.src, `promo_${id}.png`);

        const res = await uploadPromotionAssets(user.token, formData);
        if(res){
            const nChangePromoAssets = promoAssetChanges.map((change) => {
                if(change.id !== id) return change;
            });

            setPromoAssetChanges(nChangePromoAssets);

            const nPromos = businessPromos.map((promo) => {
                if(promo._id === id){
                    return {...promo, image: res.name};
                }

                return promo;
            });

            setBusinesPromos(nPromos);

        }
    }

    const showUpdatePromoAsset = (promoId) =>{
        let res;
        if(promoAssetChanges.length > 0){
            promoAssetChanges.map((assetChange) =>{
                if(assetChange && assetChange.id === promoId){
                    res = (
                        <button class="w-[80%] bg-green-400 h-8 text-white mt-2 rounded-md hover:shadow-xl hover:scale-105"
                            onClick={() => updatePromoAsset(assetChange)}>Actualziar</button>
                    )
                }
            })
        }

        return res
    }

    const changePromoAsset = (asset, promoId) => {
        const changeAsset = {id:promoId, asset};
        var newPromoAssets;
        let finded = false;

        if(promoAssetChanges.length >0){
            newPromoAssets = promoAssetChanges.map((assetChange) =>{
                if(assetChange && assetChange.id === promoId){
                    finded = true;
                    return {...assetChange, asset: asset};
                }
                else return assetChange;
            });

            if(finded){
                setPromoAssetChanges(newPromoAssets);
            }else{
                newPromoAssets = [...promoAssetChanges, changeAsset];
                setPromoAssetChanges(newPromoAssets);
            }
        }
        else{
            newPromoAssets = [...promoAssetChanges, changeAsset];
            setPromoAssetChanges(newPromoAssets);
        }
        
        newPromoAssets = null;
    }

    const renderPromos = () => {
        if(businessPromos && businessPromos.length >0){
            let promos = businessPromos.map((promo) => {
                
                let imgUrl;
                if(promo.image){
                    imgUrl = ASSETS_URL + businessInfo.userName + '/promos/' + promo.image;
                }
                return(
                    <div class="bg-white border p-2 rounded-xl shadow-md flex sm:flex-col xl:flex-col hover:scale-105 hover:shadow-xl transition-transform" >
                        <div class="mr-4">
                            <Switch Togle={promo.enabled} TogleCallback={() => toglePromo(promo.enabled, promo._id)}/>
                        </div>
                        <div class="w-80 p-2">
                            <div class="group">
                                <EditableLabel labelText={promo.product} labelCustomStyle="text-xl" 
                                    updateLabelCallback={(data) => updatePromoData(data, 'product', promo._id)} />
                            </div>
                            <div class="mt-1 ml-0 lg:ml-2 group">
                                <EditableLabel labelText={promo.description} labelCustomStyle="text-m truncate" 
                                    updateLabelCallback={(data) => updatePromoData(data, 'description', promo._id)} />
                            </div>
                            <div class="mt-8 ml-0 lg:ml-2 group">
                                <EditableLabel labelText={promo.requirement} labelCustomStyle="text-lg italic truncate"
                                    updateLabelCallback={(data) => updatePromoData(data, 'requirement', promo._id)} />
                            </div>
                            <div className="w-full h-fit mt-2">
                                <PromotionType type={promo.type} amount={promo.discountAmount}  
                                   updateCallBack={(data)=> updatePromoData(data, 'promotion', promo._id)} />
                            </div>
                            <div class="mt-2">
                                <CheckBox Label="Exclusivo Ziti" Selected={promo.usersOnly} SelectCallback={(enabled) => updatePromoData(enabled, 'usersOnly', promo._id)}/>
                                <CheckBox Label="Promo Principal" ToolTip="Se mostrara en la tarjeta del negocio" Selected={promo.mainPromo} SelectCallback={(enabled) => updatePromoData(enabled, 'mainPromo', promo._id)}/>
                            </div>
                        </div>
                        <div class="h-fit flex flex-col items-center">
                            <div class="h-36 w-48">
                                <ImageUploadPreview Name={`promo${promo._id}`} Label="" Src={imgUrl} AddAssetCallback={(src) => changePromoAsset(src, promo._id)} Saving={false}/>
                            </div>
                            {showUpdatePromoAsset(promo._id)}
                        </div>
                    </div>
                )
            });

            return promos;
        }
    }

    const labelStyle = "mr-4 italic text-lg";
    const EditableStyle = "mr-4 italic text-2xl md:text-xl sm:text-lg sm:truncate";
    const rowStyle = "flex flex-row 2xl:w-[100%] items-baseline mt-4";
    const tabs = [
        { "title": "Información",
          "content":
            <div>
                <div class="border p-4 flex flex-col">
                    <div class={"flex flex-row 2xl:w-[100%] items-baseline"}>
                        <p class={labelStyle}>Usuario: </p>
                        <EditableLabel labelText={businessInfo.userName}  labelCustomStyle={EditableStyle}/>
                        {/* TODO add password change */}
                    </div>
                    <div class={rowStyle + " group"}>
                        <p class={labelStyle}>Email: </p>
                        <EditableLabel labelText={businessInfo.email} labelCustomStyle={EditableStyle} updateLabelCallback={(data) => updateData(data, 'email')}/>
                    </div>
                    <div class={rowStyle + " group"}>
                        <p class={labelStyle}>Dirección: </p>
                        <div class="w-[150%]">
                            <EditableLabel labelText={businessInfo.address} labelCustomStyle={EditableStyle} updateLabelCallback={(data) => updateData(data, 'address')}/>
                        </div>
                    </div>
                    <div class={rowStyle}>
                        <div class="w-[40%] flex flex-row group items-center">
                            <p class={labelStyle}>Latitud: </p> <EditableLabel labelText={businessInfo.geolocalization? businessInfo.geolocalization.lat: ""} 
                                labelCustomStyle={EditableStyle} updateLabelCallback={(data) => updateData(data, 'lat')}/>
                        </div>
                        <div class="w-[40%] flex flex-row group items-center">
                            <p class={labelStyle}>Longitud: </p> <EditableLabel labelText={businessInfo.geolocalization? businessInfo.geolocalization.lon: ""} 
                                labelCustomStyle={EditableStyle} updateLabelCallback={(data) => updateData(data, 'lon')}/>
                        </div>
                    </div>
                </div>                
                <div class="mt-6 p-4 flex flex-col items-center border">
                    <div class="w-full h-10 flex flex-row items-center text-xl font-bold italic mb-2"><p>Links</p></div>
                    <div class="w-[100%] flex flex-row h-10 items-center group">
                        <TbWorldWww class="h-[70%] w-10" />
                        <label for="businessWeb" class="block text-lg font-medium p-1 text-gray-800 w-40">Sitio Web</label>
                        <div class="w-[70%]">
                            <EditableLabel labelText={businessInfo.url? businessInfo.url: ""} maxLength={500}
                                    labelCustomStyle={EditableStyle} updateLabelCallback={(data) => updateData(data, 'url')}/>
                        </div>                            
                    </div>
                    <div class="w-[100%] flex flex-row h-10 items-center mt-2 group">
                        <BsFacebook class="h-[70%] w-10" />
                        <label for="businessFacebook" class="block text-lg font-medium p-1 text-gray-800 w-40">Facebook</label>
                        <div class="w-[70%]">
                            <EditableLabel labelText={businessInfo.url_fb? businessInfo.url_fb: ""} maxLength={500}
                                    labelCustomStyle={EditableStyle + " sm:text-wrap"} updateLabelCallback={(data) => updateData(data, 'url_fb')}/>                  
                        </div>
                    </div>
                    <div class="w-[100%] flex flex-row h-10 items-center mt-2 group mb-4">
                        <TbBrandInstagram class="h-[70%] w-10" />
                        <label for="businessInstagram" class="block text-lg font-medium p-1 text-gray-800 w-40">Instagram</label>
                        <div class="w-[70%]">
                            <EditableLabel labelText={businessInfo.url_insta? businessInfo.url_insta: ""} maxLength={500}
                                    labelCustomStyle={EditableStyle + " sm:text-wrap"} updateLabelCallback={(data) => updateData(data, 'url_insta')}/>                  
                        </div>
                    </div>
                </div>
            </div>
        },
        {
            "title": "Horario",
            "content":
                <div class="p-6 border flex flex-col items-center w-full">
                    <table class="w-[50%]">
                        <thead class="dark:border-neutral-500 font-normal text-sm border-b">
                            <tr>
                                <th class="px-6 py-4">Dia</th>
                                <th class="px-6 py-4">Apertura</th>
                                <th class="px-6 py-4">Cierre</th>
                                <th class=""><BsPlusCircle class="h-full w-6 cursor-pointer hover:scale-150 transition" onClick={() => togleNewSchedule()}/></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                bSchedule && bSchedule.sort(compareDay).map((schedule, index) => {
                                    return(
                                        <tr class="h-10 hover:bg-yellow-200" key={index}>
                                            <td class="px-6">{weekday[schedule.day]}</td>
                                            <td class="px-16">{schedule.hStart}</td>
                                            <td class="px-16">{schedule.hClose}</td>
                                            <td class="px-10 "><BsTrashFill class="cursor-pointer hover:scale-[200%] transition" onClick={() => updateDaySchedule(schedule.day, 'del')}/></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
        },
        {
            "title": "Assets",
            "content":
                <div class="p-5 w-[100%]">
                    <div class="flex flex-col items-end mb-10">
                        <button class="bg-yellow-400 h-14 p-4 shadow rounded hover:shadow-xl hover:scale-110 flex flex-row items-center transition"
                            onClick={() => updateAssets()}>
                            <BsCloudUpload class="scale-150 mr-4"/> Guardar Imagenes
                        </button>
                    </div>
                    <div class="flex sm:flex-col md:flex-row items-center">
                        <div class="w-56 h-56 ml-[auto] mr-[auto]">
                            <ImageUploadPreview Name="logo" Label="Logo" Src={GetBusinessAssetURL(businessInfo.userName, businessInfo.logo)}
                             AddAssetCallback={(src) => saveAsset(src)}/>
                        </div>
                        <div class="w-[50%] sm:w-[80%] h-[300px] mr-[auto] ml-10">
                            <ImageUploadPreview Name="background" Label="Background" Src={GetBusinessAssetURL(businessInfo.userName, businessInfo.background)} AddAssetCallback={(src) => saveAsset(src)}/>
                        </div>
                    </div>
                    <div class="flex flex-wrap mt-10 flex-col border p-2">
                        <div class="w-full h-10 flex flex-row items-center text-2xl font-bold italic"><p>Banner Principal</p></div>
                        <div class=" w-full h-auto mt-8">
                            {
                                businessInfo.mainBanner && (
                                    businessInfo.mainBanner.map((banner, index)=>{
                                        const bStyle= index===2?"mr-[25%] ml-[25%] mt-10":"mr-[5%] ml-[5%]";
                                        return(
                                            <div class={"w-[40%] h-72 shrink-0 float-left " + bStyle} key={'bp' + index}>
                                                <ImageUploadPreview Name={"BannerPrincipal" + (index + 1)} Label="" Src={GetBusinessAssetURL(businessInfo.userName, banner)} AddAssetCallback={(src) => saveAsset(src)}/>
                                            </div>
                                        )
                                    })
                                )
                            }
                        </div>
                    </div>

                    <div class="flex flex-wrap mt-10 flex-col border p-2">
                        <div class="w-full h-10 flex flex-row items-center text-2xl font-bold italic"><p>Banner Secundario</p></div>
                        <div class=" w-full h-auto mt-8">
                            {
                                businessInfo.secondBanner && (
                                    businessInfo.secondBanner.map((banner, index)=>{
                                        const bStyle= index===2?"mr-[25%] ml-[25%] mt-10":"mr-[5%] ml-[5%]";
                                        return(
                                            <div class={"w-[40%] h-72 shrink-0 float-left " + bStyle} key={'bs' + index}>
                                                <ImageUploadPreview Name={"BannerSecond" + (index + 1)} Label="" Src={GetBusinessAssetURL(businessInfo.userName, banner)} AddAssetCallback={(src) => saveAsset(src)}/>
                                            </div>
                                        )
                                    })
                                )
                            }
                        </div>
                    </div>
                    
                </div>
        },
        { "title": "Promociones",
          "content":
            <div class="w-full p-4">
                <div class="p-4">
                    <button class="bg-yellow-600 p-2 rounded shadow hover:shadow-md hover:scale-105 w-32 text-white" onClick={() => showPromotionModal(true, 1)}>Nueva</button>
                </div> 
                <div class="border p-4">
                    <div class="w-full grid gap-4 grid-cols-2">
                        { renderPromos() }
                    </div>
                </div>
            </div>
        }
    ];

    const renderCurrentTab = (tabIndex) => {
        return(
            <div>
                <div class="h-8 flex flex-row p-1 items-center">
                    {tabs.map((tab, index) =>{
                        var activeStyle = "";
                        if(index === tabIndex) activeStyle = "italic border-b-2 border-solid border-yellow-600";

                        return( 
                        <button class={"h-[175%] p-2 mr-4 hover:border-b-2 border-yellow-200 " + activeStyle}
                                onClick={() => {setCurrentTab(index); setPromoAssetChanges([])}}>
                                    <p>{tab.title}</p>
                            </button> 
                        )
                    })}
                </div>
                <div class="p-2 mt-2">
                    {tabs[tabIndex].content}
                </div>
            </div>
        );
    }

    const saveAsset = (asset) => {
        setbAssets({
            ...bAssets,
            [asset.name]:asset.src
        });
     };

    const updateData = (data, field) => {
        var sendData = {
            'id': businessInfo._id,
            'data':data,
            'field':field
        }

        if(field=== 'lat' || field === 'lon'){
            const geo = {
                ...businessInfo.geolocalization,
                [field]:data
            };
            sendData.data = geo;
            sendData.field = 'geolocalization'
        }        

        const res = updateBusinessData(user.token, sendData);

        if(res){
            const nBusiness = business.map((bObj => {
                if(bObj._id === businessInfo._id){
                    return { ...bObj, [field]: data };
                }
                return bObj;
            }));
    
            setBusinessData(nBusiness);

            const newInfo = {
                ...businessInfo,
                [field]:data
            }

            setBusinessInfo(newInfo);
            setUpdateSubCat(0);
        }    
    }

    const updatePromoData = async(data, field, id) => {
        var updateData = { field, data };
        var res = null;

        switch(field){
            case 'promotion':
                if(!data.discountAmount) data.discountAmount = 5;

                res = await updatePromotionType(user.token, data, id);
                break;
            case 'mainPromo':
                // const mainPromo = businessPromos.filter((promo) => promo.mainPromo === true && promo._id === id);
                // if(!mainPromo || mainPromo.length === 0){
                    res = await setMainPromotion(user.token, businessInfo.userName, id, {'mainPromo': data});
                // }
                break;
            default:
                res = await updatePromotionData(user.token, updateData, id);
                break;
        }
        
        if(res){
            updatePromo(id, field, data);
        }
    }

    const toglePromo = async(currentState, promoId) => {
        var res;

        if(currentState){
            res = await disablePromotion(user.token, promoId);
        }else{
            res = await enablePromotion(user.token, promoId);
        }

        if(res){
            updatePromo(promoId, 'enabled', !currentState);
        }
    }

    const updateDaySchedule = (data, action) => {
        let newSchedule;
        
        if(action === 'del'){
            newSchedule = businessInfo.schedule.filter(function(objeto) {
                return objeto.day !== data;
            });
        }
        else{
            newSchedule = [...businessInfo.schedule, data];
        }
        
        updateData(newSchedule, 'schedule');
    }

    const updateAssets = async() =>{
        let arrayChange = false;
        let banners = ['','',''];
        let secondBanners = ['','',''];
        var index = -1;
        setIsUploadingAssets(true);
        for(const key in bAssets){
            if(bAssets.hasOwnProperty(key)){
                const asset = bAssets[key];

                const formData = new FormData();
                formData.append('userName', businessInfo.userName);
                formData.append('ref', key);
                formData.append('image', asset, `${businessInfo.userName}_${key}.png`);

                const res = await uploadBusinessAssets(user.token, formData);
                
                if(res){
                    if(key.includes('BannerPrincipal') || key.includes('BannerSecond')){
                        index = GetArrayPositionFromString(key);
                    }

                    if(key === 'logo' || key === 'background'){
                        businessInfo[key] = res.name;
                        updateData(res.name, key);
                    }
                    else if(key.includes('BannerPrincipal')){
                        if(index !== -1){
                            banners[index] = res.name;
                        }
                    }
                    else if( key.includes('BannerSecond')){
                        if(index !== -1){
                            secondBanners[index] = res.name;
                        }
                    }
                }
                else{
                    // setErrorAssets([...errorAssets, key]);
                    // uploadingError = true;
                }
            }
        }

        var nBanners = businessInfo.mainBanner;
        var nSecondBanner = businessInfo.secondBanner;

        banners.map((banner, index) => {
            if(banner !== '') {
                nBanners[index] = banner;
                arrayChange = true;
            }
        });

        if(arrayChange){
            updateData(nBanners, 'mainBanner');
        }
        arrayChange = false;

        secondBanners.map((sbanner, index)=>{
            if(sbanner !== '') {
                nSecondBanner[index] = sbanner;
                arrayChange = true;
            }
        });
        if(arrayChange){
            updateData(nSecondBanner, 'secondBanner');
        }
        setIsUploadingAssets(false);        
    }

    return (
        <div class="h-full sm:w-[90%] w-full xl:w-full">
            <div class="flex-row h-10 mt-1 flex items-center mb-10">
                <IoIosArrowBack class="h-8 w-8 cursor-pointer" onClick={()=> navigate('/Business/')}/><p class=" ml-4 font-semibold text-4xl">Detalles</p>                    
            </div>
            
            { isLoadingDetails? 
                <div class="w-[25%] h-[25%] m-auto mb-auto mt-[25%]">
                    <Loading fullScreen={false}/>
                </div> 
                :
                <div class="2xl:p-4">
                    <div class="flex flex-row items-center">
                        <div class="w-32 h-32 lg:p-1">
                            { renderLogoImage(businessInfo.logo, businessInfo.name, businessInfo.userName) }
                        </div>
                        <div>
                            <div class="2xl:px-6 2xl:py-4 text-xl">
                                <EditableLabel labelText={businessInfo.name} labelCustomStyle="text-4xl"/>
                                <div class="group w-[90%]">
                                    <EditableLabel labelText={businessInfo.description} 
                                        labelCustomStyle="text-slate-700 italic ml-3 text-base" updateLabelCallback={(data) => updateData(data, 'description')}/>
                                </div>
                                <p class="2xl:text-xs text-gray-600 italic mt-4 ml-2">Agregado el {FormatDate(businessInfo.addedDate)}</p>
                            </div>
                            <div class="h-[100%] ml-auto flex flex-row mr-[auto] mt-[auto] items-center">
                                <ComboBox Name="category" Label="" data={categories} SelectItemCallback={(id) => selectCategory(id)} SelectedText={categorySelected?categorySelected.name:''}/>
                                <ComboBox Name="subcategory" class="mt-2" Label="" data={subCategories} SelectItemCallback={(id) => selectSubCategory(id)} SelectedText={subCategorySelected?subCategorySelected.name : ''}/>
                                { updateSubCat === 2 && 
                                    <button class="h-12 w-12 flex flex-col items-center bg-green-500 rounded shadow-sm hover:shadow-xl"
                                        onClick={() => updateData(subCategorySelected.id, 'subcategoriesId')}><GrUpdate class="h-full w-[50%]"/></button>
                                }
                            </div>
                        </div>
                    </div>
                    <div class="mt-2 p-4">
                        {businessInfo && renderCurrentTab(currentTab)}
                    </div>
                </div>
            }
            
            { isOpen && (
                <SelectScheduleModal Close={togleNewSchedule} Schedule={businessInfo.schedule} AddSchedule={updateDaySchedule} />
            )}
            { showPromoModal && (
                <PromoModal Action={promoAction} CloseModal={() => showPromotionModal(false, 0)} Business={businessInfo.userName}
                    ActionCallback={(promo) => promoActionHandler(promo)}/>
            )}
            { isUploadingAssets && (
                <Loading fullScreen={true} loader={false} message="Guardando..."/>
            )}
        </div>
    );
};

export default BusinesDetails;