import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { MdOutlineSearch } from "react-icons/md";

import { useUser } from '../context/UserContext.js';
import { useBusiness } from "../context/BusinessContext.js";

import EditableLabel from "../components/EditableLabel.js";

import {  } from "../services/ziti.js";
import { enable, disable, updateBusinessData } from "../services/ziti.business.js";
import { ASSETS_URL } from '../constants.js';

import logo from '../assets/ziti_icon.png'

const Bussines = () => {
    const { user } = useUser();
    const { business, isLoadingBusiness, setLoadingBusiness, setBusinessData } = useBusiness();
    
    const [isOpen, setIsOpen] = useState(false);
    const [filteredBusiness, setFilteredBusiness] = useState(business);

    const navigate = useNavigate();   

    useEffect(() =>{
        if(!isLoadingBusiness && !business){
            
        }
    });

    const newBusiness = () => navigate('/Business/New');

    const renderLogo = (url, altText, business) => {
        if(url){
            const imgUrl = ASSETS_URL + business + '/' + url;
            
            return <img src={imgUrl} class="h-20 bg-yellow-100 aspect-square border rounded-lg hover:scale-150 ease-in-out duration-300" alt={altText}/>
        }
        else{
            return <img src={logo} class="p-1 opacity-50 bg-yellow-100 border rounded-lg" alt={altText}/>
        }
    }

    const filterBusiness = (find) => {
        if(find.lenth ===0){
            setFilteredBusiness(business);
        }
        else{
            const filtered = business.filter(b => b.name.toLowerCase().includes(find));
            setFilteredBusiness(filtered);
        }
    }

    const togleBusiness = (bObj) => {
        console.log('togle')
        const { _id } = bObj;
        var res = false;

        if(bObj.enabled){
            res = disable(user.token, _id);
        }
        else{
            res = enable(user.token, _id);
        }
        
        console.log(res)
        if(res){
            const nBusiness = business.map((b => {
                if(b._id === _id){
                    return { ...b, enabled: !b.enabled };
                }
                return b;
            }));

            setBusinessData(nBusiness);
            setFilteredBusiness(nBusiness);
        }
    }

    const updateBusinessName = (text, id) => {
        const data = {
            'id': id,
            'data':text,
            'field':'name'
        }

        const res = updateBusinessData(user.token, data);

        if(res){
            const nBusiness = business.map((bObj => {
                if(bObj._id === id){
                    return { ...bObj, name: text };
                }
                return bObj;
            }));
    
            setBusinessData(nBusiness);
            setFilteredBusiness(nBusiness);
        }        
    }

    return (
        <div class="p-2 w-full">
            <div className="w-full">
                <div class="flex-row h-10 text-center mt-1">
                    <p class=" mr-10 font-semibold text-4xl">Negocios</p>                    
                </div>
                <div class="mt-20 flex justify-center items-center flex-col">
                    <div class="h-12 w-full flex flex-row">
                        <div class="w-[80%] md:w-[80%] lg:w-[50%] xl:w-[35%] h-full items-center flex flex-row">
                            <input class="h-12 w-[85%] rounded bg-slate-100 border p-1" placeholder="Buscar Negocio..." onChange={(e) => filterBusiness(e.target.value)}></input>
                            <MdOutlineSearch  class="ml-1 h-[90%] w-fit p-1.5 opacity-70"/>
                        </div>
                        <div class="">
                            <button
                                    type="button"
                                    class="rounded bg-yellow-600 px-6 pb-2 pt-2.5 text-s font-medium 
                                    leading-normal text-white shadow-md transition duration-150 ease-in-out 
                                    hover:bg-primary-600 hover:shadow-xl active:bg-primary-700"
                                    onClick={() => newBusiness()}>
                                    Agregar
                                </button>
                            </div>
                    </div>
                    <div class="h-1 w-[96%] bg-neutral-100 opacity-100 mt-4" />
                    <div class="w-full mt-3">
                        <table class="min-w-50 text-left text-sm font-light w-full">
                                <thead class="border-b font-medium dark:border-neutral-500">
                                    <tr class="h-10">
                                        <th scope="col" class="px-6 py-4 text-xl w-1">Estado</th>
                                        <th scope="col" class="px-6 py-4 text-xl w-1">Logo</th>
                                        <th scope="col" class="px-6 py-4 text-xl">Nombre</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { filteredBusiness && filteredBusiness.map((obj) => {
                                    return(
                                        <tr class="border-b dark:border-neutral-500 hover:bg-yellow-100 group transition duration-400 ease-in-ou" key={obj._id}>
                                            <td class="whitespace-nowrap px-6 py-4 font-medium">
                                                <label class="relative inline-flex items-center cursor-pointer">
                                                    <input type="checkbox" value="testing" class="sr-only peer" checked={obj.enabled} onChange={() => togleBusiness(obj)}/>
                                                    <div class="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                </label>
                                            </td>
                                            <td class="whitespace-nowrap text-lg">
                                                <div class="2xl:w-full 2xl:h-20">
                                                    { renderLogo(obj.logo, obj.name, obj.userName) }
                                                </div>
                                            </td>
                                            <td class="whitespace-nowrap px-6 py-4 text-lg">
                                                <EditableLabel labelText={obj.name} labelClickCallback={() => navigate('/Business/'+obj.userName)} updateLabelCallback={(text) => updateBusinessName(text, obj._id)} />
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                </div>
            </div>
        </div>
    );
};

export default Bussines;