import React, { useState } from "react";
import PropTypes from 'prop-types';

import { useCategories } from "../context/CategoriesContext.js";
import { useUser } from "../context/UserContext.js";

import { addCategory } from "../services/ziti.js";

const AddCategory = ({CloseModal}) => {
    const [categoryName, setCategoryName] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    
    const { addNewCategory } = useCategories();
    const { user } = useUser();

    const saveCategory = async () => {
        if(isSaving) return;
        if(categoryName === '') return;

        setIsSaving(true);
        let resCategory = await addCategory(user.token, categoryName);
        if(resCategory) {
            const newCat = resCategory.data;

            addNewCategory(newCat);
            setIsSaving(false);
            CloseModal('New');
        }
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>

            <div className="bg-white w-[50%] absolute xl:w-1/4 origin-center top-[h-screen] left-[w-screen] rounded z-50">
                <div className="py-4 text-left px-6">
                    <div className="flex justify-between items-center">
                        <h2 className="text-xl font-semibold">Agregar Categoria</h2>
                        <button
                        onClick={() => CloseModal('New')}
                        className="text-gray-600 hover:text-gray-800 focus:outline-none"
                        >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-x"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#000000"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="18" y1="6" x2="6" y2="18" />
                            <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                        </button>
                    </div>
            
                    <div class="mt-10">
                        <div class="flex-col">
                            <label for="name" class="block mb-4 text-lg font-medium text-gray-900">Nombre de la categoria:</label>
                            <input id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[80%] p-2.5 ml-auto mr-auto" maxLength={30}
                                onChange={ e => setCategoryName(e.target.value) }></input>
                            <div class="w-full bg-slate-600 mt-10 items-center">
                                <button class="bg-green-500 p-2 rounded w-32 hover:shadow-lx float-right mb-5 text-white" 
                                onClick={() => saveCategory()}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

AddCategory.propTypes = {
    CloseModal: PropTypes.func.isRequired
}

export default AddCategory;