import React, { useState, createContext, useContext } from "react";

const CategoriesContext = createContext();

export function useCategories() {
    return useContext(CategoriesContext);
  }
  
export function CategoriesProvider({ children }) {
    const [categories, setCategories] = useState([]);
    const [isLoadingCategories, setLoading] = useState(false);

    const setIsLoadingCategories = (loading) => {
        setLoading(loading);
    }

    const setCategoriesData = (data) => {
        setCategories(data);
    }

    const addNewCategory = (newCategory) => {
        const newArrayCat = [
            ...categories,
            newCategory
        ]
        setCategories(newArrayCat);
    }

    return (
        <CategoriesContext.Provider value={{ categories, isLoadingCategories, setIsLoadingCategories, setCategoriesData, addNewCategory }}>
            {children}
        </CategoriesContext.Provider>
    );
}