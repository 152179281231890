import { API_URL } from "../constants";

const loginServer = async (user, pwd) => { 
    return await fetch(API_URL + "/login", {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json' ,
        },
        body: JSON.stringify({ user, pwd })
      })
      .then(response => {
        if (!response.ok) {
          //throw new Error('Inicio de sesión fallido');
          return null;
        }
        return response.json();
      })
      .then(data => {
        return data;
      })
      .catch(error => {     
        console.error('Error de inicio de sesión:', error);
        return null;
      });
}

const fetchCategories = async (token, callback) => {
  return await fetch (API_URL + '/category',{
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  })
  .then(response => {
    if (!response.ok) {
      return null;
    }
    return response.json();
  })
  .then(data => {
    if(callback) callback(data);
    
    return data;
  })
  .catch(error => {     
    console.error('Error al cargar los datos:', error);
    return null;
  });
}

const enableCategory = async (token, id) => {
  return await fetch(API_URL + '/category/' + id, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  })
  .then(response =>{
    if(!response.ok){
      return null;
    }
    return true;
  })
  .catch(error => {
    console.error('Error al actualizar: ', error);
    return null;
  });
}

const disableCategory = async (token, id) => {
  return await fetch(API_URL + '/category/' + id, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  })
  .then(response =>{
    if(!response.ok){
      return null;
    }
    return true;
  })
  .catch(error => {
    console.error('Error al actualizar: ', error);
    return null;
  });
}

const addCategory = async (token, data) => {
  return await fetch(API_URL + '/category', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({ 'name':data })
  })
  .then(response =>{
    if(!response.ok){
      return null;
    }
    return response.json();
  })
  .then (data =>{
    return data;
  })
  .catch(error =>{
    console.error('Error al guardar datos: ' + error);
    return null;
  })
}

const addSubCategory = async (token, data) => {
  return await fetch(API_URL + '/subcategory/' + data.categoryId,{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({ 'name':data.SubCategoryName })
  })
  .then(response => {
    if(!response.ok){
      return null
    }
    return response.json();
  })
  .then (data => {
    //console.log(data);
    return data;
  })
  .catch(error =>{
    console.error('Error al guardar datos: ' * error);
    return null;
  })
}

const enableSubCategory = async (token, id) => {
  return await fetch(API_URL + '/subcategory/' + id, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  })
  .then(response =>{
    if(!response.ok){
      return null;
    }
    return true;
  })
  .catch(error => {
    console.error('Error al actualizar: ', error);
    return null;
  });
}

const disableSubCategory = async (token, id) => {
  return await fetch(API_URL + '/subcategory/' + id, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  })
  .then(response =>{
    if(!response.ok){
      return null;
    }
    return true;
  })
  .catch(error => {
    console.error('Error al actualizar: ', error);
    return null;
  });
}

const updateCategoryData = async (token, updateData) => {
  return await fetch(API_URL + '/category/' + updateData.id,{
    method:'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({ [updateData.field]:updateData.data })
  })
  .then(response =>{
    if(!response.ok){
      return null;
    }
    return true;
  })
  .catch(error => {
    console.error('Error al actualizar: ', error);
    return null;
  });
}

const updateSubCategoryData = async (token, updateData) => {
  return await fetch(API_URL + '/subcategory/' + updateData.id,{
    method:'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({ [updateData.field]:updateData.data })
  })
  .then(response =>{
    if(!response.ok){
      return null;
    }
    return true;
  })
  .catch(error => {
    console.error('Error al actualizar: ', error);
    return null;
  });
}


export { loginServer, fetchCategories, enableCategory, disableCategory, addCategory, addSubCategory, enableSubCategory, 
  disableSubCategory, updateCategoryData, updateSubCategoryData, 
};