import { API_URL } from "../constants";

const addBusiness = async (token, businessData) =>{
    return await fetch(API_URL + '/business', {
      method:'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body:JSON.stringify(businessData)
    })
      .then(response =>{
        if(!response.ok){
          return null;
        }
        return response.json();
      })
      .then(data => {
        return data.id;
      })
      .catch(error => {
        console.error('Error: ', error);
        return null;
      });
  }
  
  const uploadBusinessAssets = async (token, assetData) => {
    return await fetch(API_URL + '/business/upload/',{
      method:'POST',
      headers: {
        'Authorization': 'Bearer ' + token
      },
      body: assetData
    })
    .then(response =>{
      if(!response.ok) return false;
  
      return response.json();
    })
    .catch(error => {
      console.error(error);
      return false;
    })
  }

  const enable = async (token, id) => {
    return await fetch(API_URL + '/business/' + id, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(response =>{
      if(!response.ok){
        return null;
      }
      return true;
    })
    .catch(error => {
      console.error('Error al actualizar: ', error);
      return null;
    });
  }
  
  const disable = async (token, id) => {
    return await fetch(API_URL + '/business/' + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(response =>{
      if(!response.ok){
        return null;
      }
      return true;
    })
    .catch(error => {
      console.error('Error al actualizar: ', error);
      return null;
    });
  }

  const updateBusinessData = async (token, data) => {
    return await fetch(API_URL + '/Business/' + data.id,{
      method:'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ [data.field]:data.data })
    })
    .then(response =>{
      if(!response.ok){
        return null;
      }
      return true;
    })
    .catch(error => {
      console.error('Error al actualizar: ', error);
      return null;
    });
  }
  
  const fetchBusiness = async (token, callback) =>{
    return await fetch (API_URL + '/business',{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(response => {
      if (!response.ok) {
        return null;
      }
      return response.json();
    })
    .then(data => {
      if(callback) callback(data);
      
      return data;
    })
    .catch(error => {     
      console.error('Error al cargar los datos:', error);
      return null;
    });
  }
  
  const fecthBusinessDetail = async (token, bussinesUser,callback) =>{
    return await fetch (API_URL + '/business/' + bussinesUser,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(response => {
      if (!response.ok) {
        return null;
      }
      return response.json();
    })
    .then(data => {
      if(callback) callback(data);
      
      return data;
    })
    .catch(error => {     
      console.error('Error al cargar los datos:', error);
      return null;
    });
  }

  export {  addBusiness, uploadBusinessAssets, enable, disable, fetchBusiness, updateBusinessData, fecthBusinessDetail }