import React from "react";
import PropTypes from 'prop-types';
import Lottie from 'react-lottie-player';

import savingAnim from '../assets/animations/saving.json';
import loading from '../assets/animations/loading.json';
import { BiErrorCircle } from 'react-icons/bi';

const Loading = ({fullScreen, loader, message}) => {
    return (
        <div className="h-full">
            { fullScreen? (
                <div className={"fixed inset-0 flex items-center justify-center z-40"}>
                    <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
                    <div className="modal-container mx-auto rounded z-50 overflow-y-none flex flex-col items-center">
                        <div className="w-[50%] h-[100%]">
                            <Lottie animationData={loader? loading: savingAnim} play loop/>
                        </div>
                        {message && (
                            <p className="text-5xl text-white">Guardando.....</p>
                        )}                
                    </div>
                </div>
            ): (
                <div className="h-full aspect-square ml-auto mr-auto">

                        <Lottie animationData={loading} play loop />
                        {/* <div className="w-full h-full bg-yellow-400"></div> */}
                </div>
            )}
            {/* style={{ height: '100%', width: '30px', backgroundColor: 'purple' }} */}
                
        </div>
    );
};

Loading.propTypes = {
    fullScreen: PropTypes.bool,
    animation: PropTypes.string,
    loader: PropTypes.bool, 
    message: PropTypes.string,
}

export default Loading;