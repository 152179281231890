import { useState, createContext, useContext } from "react";

const BusinessContext = createContext();

export function useBusiness() {
    return useContext(BusinessContext);
  }
  
export function BusinessProvider({ children }) {
    const [business, setBusiness] = useState([]);
    const [isLoadingBusiness, setIsLoadingBusiness] = useState(false);
    const [isLoadingDetails, setIsLoadinDetails] = useState(false);
    const [isUploadingAssets, setIsUploadingAssets] = useState(false);

    const setBusinessData = (data) => setBusiness(data);

    const addNewBusiness = (newBusiness) => {
        const newArray = [
            ...business,
            newBusiness
        ]
        setBusiness(newArray);
        return true;
    }

    const setLoadingBusiness = (isLoading) => setIsLoadingBusiness(isLoading);
    const setLoadinDetails = (isLoading) => setIsLoadinDetails(isLoading);
    const setUploadingAssets = (isUploading) => setIsUploadingAssets(isUploading);

    return (
        <BusinessContext.Provider value={{ business, addNewBusiness, isLoadingBusiness, setLoadingBusiness, setBusinessData,
            isLoadingDetails, setLoadinDetails, setUploadingAssets, isUploadingAssets }}>
            {children}
        </BusinessContext.Provider>
    );
}