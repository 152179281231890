import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from './pages/Layout';
import Home from './pages/Home';
import Business from './pages/Business';
import BusinesDetails from './pages/BusinessDetails';
import NewBusiness from './pages/AddBusiness';
import Categories from './pages/Categories';
import AddBusiness from "./pages/AddBusiness";

export default function RouterApp() {
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={ <Layout /> } >
                    <Route index element={ <Home /> } />
                    <Route path="/Home" element= { <Home /> } />
                    <Route path="/Business" element={ <Business />} />
                    <Route path="/Business/New" element= { <AddBusiness /> } />
                    <Route path="/Business/:id" element= { <BusinesDetails /> } />
                    <Route path="/Categories" element={ <Categories />} />
                    <Route path="*" element={ <h1 class="text-red-800 text-5xl">No Page!!</h1>} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}