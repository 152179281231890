import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { BiImageAdd, BiImage } from 'react-icons/bi';

import { ASSETS_URL } from '../constants';

const ImageUploadPreview = ({Name, Label, AddAssetCallback, Src, Saving}) => {
    const [previewImage, setPreviewImage] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
          //setSelectedImage(file); // Almacena el archivo seleccionado en el estado
    
          const reader = new FileReader();
          reader.onload = (e) => {
            setPreviewImage(e.target.result); // Establece la URL de datos (base64) en el estado
            const asset = {
                'name': Name,
                'src': file
            }
            if(AddAssetCallback) AddAssetCallback(asset);
          };
    
          // Lee la imagen como URL de datos
          reader.readAsDataURL(file);
        }
      };

    useEffect(() =>{
        if(Src && !previewImage){
            setPreviewImage(Src);
        }
    });

    return(
        <div class="flex items-center justify-center w-full h-full flex-col">
            <label for={Name} class="group/item flex flex-col items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-lg 
            cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 
            dark:hover:border-gray-500 dark:hover:bg-gray-600">
                { !previewImage && <BiImage class="w-[60%] h-[60%] text-gray-500 group-hover/item:hidden" /> }                
                { previewImage && <img src={previewImage} alt="" style={{ maxWidth: '100%', maxHeight:'100%' }} class="h-full w-full group-hover/item:hidden rounded  "/> }
                <div class="h-full w-full hidden group-hover/item:flex">
                    <div class="flex flex-col items-center h-full  w-full bg-yellow-100">
                        <BiImageAdd class="w-[30%] h-[100%] mb text-gray-500 " >
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                        </BiImageAdd>
                        <p class="mb-2 text-xs text-gray-500 dark:text-gray-400 text-center"><span class="font-normal">Click para seleccionar Imagen</span></p>
                    </div>
                </div>
                <input name={Name} id={Name} type="file" class="hidden" onChange={handleImageChange} accept="image/x-png,image/jpeg" disabled={Saving}/>
            </label>
            <p class="text-center text-gray-900 text-m">{Label}</p>
        </div> 
    )
}

ImageUploadPreview.propTypes = {
    Name: Proptypes.string.isRequired,
    Label: Proptypes.string,
    AddAssetCallback: Proptypes.func.isRequired,
    src: Proptypes.string,
    Saving: Proptypes.bool
}

export default ImageUploadPreview;