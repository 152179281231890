import React, { useState, createContext, useContext } from "react";

const UserContext = createContext();

export function useUser() {
    return useContext(UserContext);
  }
  
export function UserProvider({ children }) {
    const [user, setUser] = useState(null);

    const login = (userData) => {
        setUser(userData);
    };

    const logout = () => {
        setUser(null);
    };

    const updateUserData = () => {

    }

    return (
        <UserContext.Provider value={{ user, login, logout, updateUserData }}>
            {children}
        </UserContext.Provider>
    );
}