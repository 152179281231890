import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import { MdModeEdit, MdDone, MdClear } from "react-icons/md";

const ComboBox = ({Label, data, SelectItemCallback, Name, SelectedText}) => {
    const [isOpen, setisOpen] = useState(false);
    //const [selectedText, setselectedText] = useState('');
    //const [selectedId, setselectedId] = useState('');

    const togleCombo = () => setisOpen(!isOpen);

    const setselectedItem = (item) => {
        SelectItemCallback(item);

        setisOpen(false);
    }

    const clearSelection = () => {
        //setselectedText('');
        //setselectedId('');
        SelectItemCallback(null);
        setisOpen(false);
    }

    const renderData = ()  => {
        if(data !== null && data.length>0){
            const ítems = data.map((item) => {
                //console.log(item)
                return(
                    <div class="cursor-pointer group border-t">
                        <p class="block p-2 border-transparent border-l-4 group-hover:border-yellow-600 group-hover:bg-gray-100" 
                            onClick={() => setselectedItem(item)}>
                        {item.name}
                        </p>
                    </div>
                )
            });
            return ítems;
        }
    }

    return(
        <div class="p-4">
            <div class="max-w-md mx-auto">
                { Label && <label for="select" class="block py-2 mb-2">{Label}</label> }
                
                <div class="relative">
                    <div class="h-10 bg-white flex border border-gray-200 rounded items-center">
                        <input value={SelectedText} name="select" id="select" class="px-4 appearance-none outline-none text-gray-800 w-full disabled:bg-transparent" checked disabled/>

                        { 
                            SelectedText && SelectedText.length > 1 &&
                                <button class="cursor-pointer transition-all text-gray-300 hover:text-gray-600" onClick={() => clearSelection()}>
                                    <MdClear class="w-4 h-4 mx-2 fill-current" >
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </MdClear>
                                </button>
                        }
                        
                        <label for={Name} class="cursor-pointer outline-none focus:outline-none border-l border-gray-200 transition-all text-gray-300 hover:text-gray-600">
                            <svg class="w-4 h-4 mx-2 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <polyline points="18 15 12 9 6 15"></polyline>
                            </svg>
                        </label>
                    </div>

                    <input type="checkbox" id={Name} class="hidden peer" checked={isOpen} onClick={() => togleCombo()} />
                    <div class="absolute z-10 rounded shadow bg-white overflow-hidden hidden peer-checked:flex flex-col w-full mt-1 border border-gray-200">
                        {
                            renderData()
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

ComboBox.propTypes = {
    Label: PropTypes.string,
    SelectItemCallback: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    Name: PropTypes.string.isRequired,
    SelectedText: PropTypes.string.isRequired
}

export default ComboBox;