import React, { useState } from "react";
import PropTypes from 'prop-types';
import { MdOutlineClose } from 'react-icons/md';


const SelectScheduleModal = ({ Close, Schedule, AddSchedule }) => {
    const [day, setDay] = useState({});
    const [hourOpen, setHourOpen] = useState('12');
    const [minOpen, setMinOpen] = useState('00');
    const [hourClose, setHourClose] = useState('13');
    const [minClose, setMinClose] = useState('00');
    const [errorMessage, setErrorMessage] = useState('');

    const renderHoursOptions = (plus) => {
        var options = [];

        for (var i = 0; i < 25; i++) {
            options.push(<option selected={i===(12+plus)?true: false}>{i}</option>);
        }

        return options;
    }

    const selectDay = (data) => {
        var selectedDay = {
            name: data.value,
            number: data.selectedIndex -1
        }
        setDay(selectedDay);
    }

    const selectOpenHour = (data) => setHourOpen(data);
    const selectOpenMinute = (data) => setMinOpen(data);

    const selectCloseHour = (data) => setHourClose(data);
    const selectCloseMinute = (data) => setMinClose(data);

    const checkData = () => {
        var open = hourOpen + ":" + minOpen;
        var close = hourClose + ":" + minClose;
        
        if(Object.keys(day).length === 0){
            setErrorMessage('Selecciona el dia');
            return;
        }
        else if(open === close){
            setErrorMessage('Verifica el horario, no pueden ser iguales');
            return;
        }
        else if(Schedule.length > 0){
            var finded = Schedule.find((schedule) => schedule.day === day.number)? true : false;

            if(finded){
                setErrorMessage('El dia ya esta agregado, selecciona otro dia');
                return;
            }
            else{
                var schedule = {
                    'day':day.number,
                    'hStart': open,
                    'hClose': close
                }
                AddSchedule(schedule);
                Close();
            }
        }
        else{
            var schedule = {
                'day':day.number,
                'hStart': open,
                'hClose': close
            }
            AddSchedule(schedule);
            Close();
        }

        setErrorMessage('');
    }

    return(
        <div className="fixed inset-0 flex items-center justify-center z-40">
            <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>

            <div className="modal-container bg-white 2xl:w-[50%] mx-auto rounded shadow-lg z-50 overflow-y-auto">
                <div className="modal-content py-4 text-left px-6">
                    <div className="flex justify-between items-center">
                        <h2 className="text-xl font-semibold">Agregar Horario</h2>
                        <button
                            onClick={() => Close()}
                            className="text-gray-600 hover:text-gray-800 focus:outline-none">
                            <MdOutlineClose className="icon icon-tabler icon-tabler-x w-8 h-8" />
                        </button>
                    </div>
                </div>
                <div class="w-fullp-2">
                    <div class="flex flex-col items-center p-4">
                        <div class="flex flex-row items-center">
                            <p>Dia: </p>
                            <select class="ml-4 rounded border border-gray-200 text-gray-600 h-10 pl-2 pr-2 
                            bg-white hover:border-gray-400 focus:outline-none appearance-none cursor-pointer" onChange={e => selectDay(e.target)}>
                                <option value="" selected disabled hidden>...</option>
                                <option>Domingo</option>
                                <option>Lunes</option>
                                <option>Martes</option>
                                <option>Miercoles</option>
                                <option>Jueves</option>
                                <option>Viernes</option>
                                <option>Sabado</option>                                
                            </select>
                            <p class="ml-10">Apertura: </p>
                            <select class="ml-4 cursor-pointer rounded border border-gray-200 text-gray-600 h-10 pl-2 pr-2 
                            text-center bg-white hover:border-gray-400 focus:outline-none appearance-none" onChange={e => selectOpenHour(e.target.value)}>
                                { renderHoursOptions(0) }
                            </select>
                            <p>:</p>
                            <select class="ml cursor-pointer rounded border border-gray-200 text-gray-600 h-10 pl-2 pr-2 
                            text-center bg-white hover:border-gray-400 focus:outline-none appearance-none" onChange={e => selectOpenMinute(e.target.value)}>
                                <option>00</option>
                                <option>15</option>
                                <option>30</option>
                                <option>45</option>
                            </select>
                            <p class="ml-10">Cierre: </p>
                            <select class="ml-4 cursor-pointer rounded border border-gray-200 text-gray-600 h-10 pl-2 pr-2 
                            text-center bg-white hover:border-gray-400 focus:outline-none appearance-none" onChange={e => selectCloseHour(e.target.value)}>
                                { renderHoursOptions(1) }
                            </select>
                            <p>:</p>
                            <select class="ml cursor-pointer rounded border border-gray-200 text-gray-600 h-10 pl-2 pr-2 
                            text-center bg-white hover:border-gray-400 focus:outline-none appearance-none" onChange={e => selectCloseMinute(e.target.value)}>
                                <option>00</option>
                                <option>15</option>
                                <option>30</option>
                                <option>45</option>
                            </select>                            
                        </div>
                        { errorMessage && (
                                <div class="bg-red-200 w-[80%] text-center rounded-md border border-red-600 h-[20%] mt-8">
                                    <p class="text-red-600 text-lg text-center">{errorMessage}</p>
                                </div>
                            )}
                        <div class="w-full mt-10">
                            <button
                                type="button"
                                class="px-6 pb-2 pt-2.5 text-s font-medium rounded float-right mr-10 bg-green-500
                                leading-normal text-white shadow-md transition duration-150 ease-in-out 
                                hover:bg-primary-600 hover:shadow-xl"
                                onClick={() => checkData()}>
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

SelectScheduleModal.propTypes = {
    Close: PropTypes.func.isRequired,
    Schedule: PropTypes.array.isRequired,
    AddSchedule: PropTypes.func.isRequired
}

export default SelectScheduleModal;