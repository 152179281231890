import React,  { useState } from "react";
import PropTypes from 'prop-types';
import { MdOutlineClose } from 'react-icons/md';

import { addPromotion, uploadPromotionAssets } from '../services/ziti.promotions.js';

import { useUser } from '../context/UserContext.js';
import { validateString } from "../utils.js";

import ImageUploadPreview from "./ImageUploaderPreview.js";
import CheckBox from "./CheckBox.js";
import CustomAmountSelect from "./CustomAmountSelect.js";

const PromoModal = ({Title, CloseModal, Business, Action, ActionCallback}) => {
    const [product, setProduct] = useState('');
    const [description, setDescription] = useState('');
    const [requirement, setRequirement] = useState('');
    const [promoimg, setPromoImg] = useState();
    const [usersOnly, setUsersOnly] = useState(false);
    const [type, setType] = useState(1); // 1 Discount, 2 Promo, 3 Product
    const [discountAmount, setDiscountAmount] = useState(5);
    const [amount1, setAmount1] = useState(1);
    const [amount2, setAmount2] = useState(1);
    const [error, setError] = useState('');
    const [isSaving, setIsSaving] = useState(false);

    const {user} = useUser();

    const closeModal = () => {
        if(isSaving) return;

        CloseModal();
    }

    const saveAsset = (asset) => {
        setPromoImg(asset.src);
    };

    const savePromo = () => {
       if(Action === 1){
        newPromo();
       }
       else if(Action === 2){
        // updatePromo()
       }
    }

    const newPromo = async() => {
        if(isSaving) return;
        setError('');
        if(!validateString(product, true)){
             setError('Ingresa un titulo correcto.'); 
             return;
        }
        if(!validateString(description, true)) {
            setError('Ingresa una descripción correcta.');
            return;
        }

        let promo = {
            product, description, requirement, usersOnly, type,
            businessId: Business,
        }
        
        if(type === 1){
            promo.discountAmount = discountAmount;
        }
        else if(type === 2){
            promo.discountAmount = amount1 + 'x' + amount2;
        }

        setIsSaving(true);
        const res = await addPromotion(user.token, promo);

        if(res){
            const id = res.data;
            promo._id = res.data;
            promo.enabled = true;
            if(promoimg){
                const formData = new FormData();
                formData.append('businessUser', Business);
                formData.append('id', id);
                formData.append('ref', 'image');
                formData.append('image', promoimg, `promo_${id}.png`);

            //     console.log(formData);
                const res = await uploadPromotionAssets(user.token, formData);
                if(res){
                    console.log(res.name);
                    promo.image = res.name;
                }
            }

            ActionCallback(promo);
            CloseModal();
        }
    }

    // TODO check customAmountSelect
    const renderDiscountOptions = (max, plus) =>{
        const options = [];
        
        for (let i = plus; i <= max; i += plus) {
            options.push(<option key={i} value={i}>{i}</option>);
        }

        return options;
    }
    
    const togleType = (type) => {
        setType(type);
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center z-40">
            <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>

            <div className="modal-container bg-white w-[90%] 2xl:w-[60%] mx-auto rounded shadow-lg z-50 overflow-y-auto">
                <div className="modal-content py-4 text-left px-6">
                    <div className="flex justify-between items-center">
                        <h2 className="text-xl font-semibold">{Action===1?'Agregar':'Actualizar'} Promoción</h2>
                        <button
                            onClick={() => closeModal()}
                            className="text-gray-600 hover:text-gray-800 focus:outline-none">
                            <MdOutlineClose className="icon icon-tabler icon-tabler-x w-8 h-8" />
                        </button>
                    </div>
                </div>
                <div class="w-full p-2">
                    <div class="flex flex-col items-center p-4">
                        <div class="w-full flex flex-row">
                            <div class="w-[70%]">
                                <div className="w-full h-14 flex flex-row items-center">
                                    <div>
                                        <input type="radio" id="discount" name="fav_language" value="discount" checked={type===1} className="cursor-pointer" onChange={() => togleType(1)}/>
                                        <label for="discount" className="cursor-pointer ml-2 text-lg hover:italic">Descuento</label>
                                    </div>
                                    <div className="ml-8">
                                        <input type="radio" id="promo" name="fav_language" value="promo" checked={type===2} className="cursor-pointer" onChange={() => togleType(2)}/>
                                        <label for="promo" className="cursor-pointer ml-2 text-lg hover:italic">Promoción</label>
                                    </div>
                                    <div className="ml-8">
                                        <input type="radio" id="product" name="fav_language" value="promo" checked={type===3} className="cursor-pointer" onChange={() => togleType(3)}/>
                                        <label for="product" className="cursor-pointer ml-2 text-lg hover:italic">Producto</label>
                                    </div>
                                </div>
                                <div className="w-full h-12 flex flex-row items-center mb-2">
                                    { type === 1 &&
                                        <div className="flex flex-row items-center">
                                            <CustomAmountSelect ExtraStyle="ml-7" MaxOptions={100} PlusOptions={5} SelectedValue={discountAmount} HandleSelectChange={(data) => setDiscountAmount(data)}/>
                                            <p className="ml-2 text-2xl font-bold">%</p>
                                        </div>
                                    }
                                    { type === 2 &&
                                        <div className="flex flex-row items-center">
                                            <CustomAmountSelect ExtraStyle="ml-7" MaxOptions={10} PlusOptions={1} SelectedValue={amount1} HandleSelectChange={(data) => setAmount1(data)}/>
                                            <p className="ml-2 text-2xl font-bold">x</p>
                                            <CustomAmountSelect ExtraStyle="ml-2" MaxOptions={10} PlusOptions={1} SelectedValue={amount2} HandleSelectChange={(data) => setAmount2(data)}/>
                                        </div>
                                    }
                                </div>
                                <div class="flex flex-row items-center mb-5">
                                    <label for="product" class="text-lg font-medium text-gray-800">Titulo</label>
                                    <input id="product" class="bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 ml-10" maxLength={100}
                                        value={product} onChange={ e => setProduct(e.target.value) }></input>
                                </div>
                                <div class="flex flex-row items-center mb-5">
                                    <label for="product" class="text-lg font-medium text-gray-800">Descripcion</label>
                                    <input id="product" class="bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 ml-3" maxLength={100}
                                        value={description} onChange={ e => setDescription(e.target.value) }></input>
                                </div>
                                <div class="flex flex-row items-center mb-5">
                                    <label for="product" class="text-lg font-medium text-gray-800">Requisitos</label>
                                    <input id="product" class="bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 ml-6" maxLength={100}
                                        value={requirement} onChange={ e => setRequirement(e.target.value) }></input>
                                </div>
                                
                                <div class="flex flex-row items-center mb-5">
                                    <CheckBox Label="Exclusivo usuarios Ziti" SelectCallback={() => setUsersOnly()}/>
                                </div>
                            </div>
                            <div class="w-[30%] flex flex-col items-center">
                                <div class="w-56 h-56">
                                    <ImageUploadPreview Name="logo" Label="" AddAssetCallback={saveAsset}/>
                                </div>
                            </div>
                            
                        </div>

                        <div class="w-full flex flex-col items-center mt-14">
                            { error && (
                                <div class="bg-red-200 mb-4 w-[50%] text-center rounded-md border border-red-600 h-[20%]">
                                    <p class="text-red-600 text-lg text-center">{error}</p>
                                </div>
                            )}
                            <button class="bg-green-500 w-32 h-10 text-white rounded shadow hover:scale-105 hover:shadow-md"
                                onClick={() => savePromo()}>Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

PromoModal.propTypes = {
    CloseModal: PropTypes.func.isRequired,
    Business: PropTypes.string,
    Action: PropTypes.number,
    ActionCallback: PropTypes.string,
}

export default PromoModal;